@import "../../assets//variables/index.scss";

.wrapp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: $wrapp-width;
    margin: $wrapp-margin;
    h1 {
        font-size: 160px;
        text-shadow: 10px 6px 8px hsla(0,0%,45.9%,.8);
        color: #00000085;
    }
    h2 {
        font-size: 60px;
        text-shadow: 10px 6px 8px hsla(0,0%,45.9%,.8);
        color: #00000085;
    }
}
