$main-background-color: #fafafb;
$secondary-background-color: #fff;
$main-transition: all 200ms ease-in-out;
$main-green-color: #5CA76A;
$disabled-opacity: 0.5;
$hover-opacity: 0.8;
$icardia-green-font: #00703C;

// fonts

$main-font: "MontserratMedium";
$secondary-font: "MontserratRegular";
$bold-font-weight: 600;
$main-font-weight: 500;
$secondary-font-weight: 400;
$main-font-style: normal;
$commune-label-font-size: 11px;
$auth-agreement-font-size: 11px;
$sidebar-title-font-size: 12px;
$details-label-font-size: 12px;
$profile-option-font-size: 14px;
$auth-validatiors-font-size: 12px;
$table-label-font-size: 16px;
$auth-agreement-line-height: 19px;
$main-font-size: 16px;
$secondary-font-size: 15px;
$auth-button-font-size: 14px;
$profile-h2-size: 20px;
$detail-item-h2-size: 21px;
$detail-item-h2-weight: 700;
$auth-label-font-size: 14px;
$filters-input-font: 14px;

// auth-page-variables

$auth-container-width: 350px;
$auth-container-boxshadow: 0px 4px 16px rgba(0, 0, 0, 0.09);
$auth-border-radius: 10px;
$auth-container-padding: 20px 40px 30px 40px;
$auth-container-zindex: 1;
$auth-logo-height: 40px;
$auth-logo-margin: 20px;
$auth-input-width: 270px;
$auth-input-height: 35px;
$auth-input-margin: 11px;
$auth-toggle-background-color: #F2F2F2;
$auth-toggle-item-width: 133px;
$auth-toggle-item-height: 29px;
$auth-toggle-login-position: 3px;
$auth-toggle-signup-position: 134px;
$auth-input-background-color: #FAFAFA;
$auth-input-hover-color: #80bdff;
$auth-default-background-color: #FFFFFF;
$auth-button-hover: #4e915a;
$auth-link-color: #2F80ED;
$auth-button-margin: 15px;
$signin-button-margin: 28px;
$auth-agreement-margin: 5px;
$auth-footer-margin: 15px;
$auth-footer-item-padding: 10px 15px 0;
$auth-footer-color: #a5b2bc;
$auth-footer-hover-color: #8798a5;

//validatiors

$auth-validatiors-top: 75px;
$auth-validatiors-errors-top: -13px;
$auth-validators-font-color: #dc3545;

//wrapp

$wrapp-height: 50vh;
$wrapp-width: 50vw;
$wrapp-margin: 0 auto;

//sidebar

$sidebar-width: 270px;
$sidebar-height: 100vh;
$sidebar-logo-height: 40px;
$sidebar-nav-item-height: 40px;
$sidebar-nav-item-padding: 12px;
$sidebar-nav-text-padding: 26px;
$sidebar-nav-item-margin: 4px 0;
$sidebar-nav-text-icon-padding: 30px;
$sidebar-nav-item-background: rgba(217, 217, 217, 0.5);
$sidebar-nav-item-background-hover: rgba(217, 217, 217, 0.3);
$sidebar-logo-wrapper-height: 90px;
$sidebar-logo-margin: 25px; 
$sidebar-font-color: #fff;
$sidebar-padding: 0 10px;
$sidebar-opacity: 0.5;
$sidebar-footer-margin: 0 auto 5px auto;
$sidebar-highlight-padding: 6px 10px;
$sidebar-highlight-radius: 5px;
$sidebar-icarda-highlight-color: #F7D99F;
$partner-block-margin: 0px 10px 15px 10px;

//map

$map-navbar-width: calc( 100vw - 400px );
$map-navbar-margin: 8px;
$map-navbar-right: 0;
$map-width: calc( 100vw - 720px );
$map-height: calc( 100vh - 105px );
$map-wrapp-padding: 10px 20px 0 20px;
$map-wrapp-color: #fff;
$map-border-radius: 8px;
$map-filters-container-height: 80px;
$map-filters-container-zindex: 1000;
$fitlers-data-for-container-width: 410px;
$fitlers-data-for-container-padding: 30px;
$fitlers-select-height: 40px;
$filters-select-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
$map-details-width: 410px;
$month-selections-padding: 0 16px;

$datafor-label-margin: 70px;
$previous-view-button-opacity: 0.8;
$previous-view-button-top: 10px; 
$previous-view-button-width: 60px;
$previous-view-button-height: 23px; 
$previous-view-button-radius: 6px;
$previous-view-button-line-height: 1em;
$previous-view-button-zindex: 1003;

//map-legend

$legend-header-padding: 0 12px 0 14px;
$legend-header-height: 29px;
$legend-width: 139px;
$legend-item-padding: 0 10px 0 10px;
$legend-color-radius: 50%;
$legend-color-size: 16px;
$legend-color-border: 1px solid #E3E1D2;
$map-legend-margin: 24px;
$map-legend-zindex: 998;

//map-details

$map-details-hr-background: #C0C0C0;
$map-details-hr-height: 1px;
$map-details-header-top-margin: 12px;
$map-details-height: calc(100vh - 90px);
$detail-item-h2-height: 29px;
$detail-item-p-margin: 6px;
$details-header-height: 190px;

$table-width: 344px;
$wide-table-width: 380px;
$table-region-top-height: 242px;
$table-commune-top-height: 50vh;
$table-border: 0.1vh solid #B9B9B9;
$table-font-size: 12px;
$table-padding: 8px;
$table-head-background: rgba(0, 0, 0, 0.06);
$table-label-margin-top: 17px;
$table-first-td-margin: 8px 0;
$table-values-td-margin: 8px 8px 8px 0;
$table-sort-margin: 6px;
$table-link-color: rgba(0, 0, 255, 0.671);

// risk-graph
$risk-graph-height: 34px;
$graph-item-width: 20%;
$risk-color-very-low: #1A9641;
$risk-color-low:#A6D96A;
$risk-color-moderate:#FFFFC0;
$risk-color-high: #FFBC2A;
$risk-color-very-heigh:#C00000;

// ndvi-graph

$ndvi-item-width: 10%;
$ndvi-border: 1px solid black;
$ndvi-graph-container-height: 50px;
$ndvi-graph-p-bottom: -23px;
$ndvi-graph-p-left: 2px;
$ndvi-graph-range-p-left: 0;
$ndvi-graph-range-font-size: 11px;
$ndvi-graph-range-value-width: 30px;
$ndvi-item-placeholder-width: 38px;
$ndvi-item-placeholder-right: -36px;
$ndvi-item-placeholder-transform: translateY(-1px);

// dashboard-mobile-view

$dashboard-tablet-width: 1199px;
$dashboard-sm-tablet-width: 997px;
$sidebar-zindex: 1005;
$sidebar-hidden-left: -270px;
$map-tablet-width: calc( 100vw - 450px );
$map-sm-tablet-width: calc( 100vw - 40px );
$show-sidebar-icon-top: 109px;
$show-sidebar-icon-radius: 5px;
$show-sidebar-icon-shadow: 0px 4px 14px rgba(0,0,0,0.1);
$show-sidebar-icon-color: #5ca76a85;
$show-sidebar-icon-padding: 1px 0;
$sidebar-transition: all 0.5s ease-in-out;
$map-details-tablet-margin: 0 auto;
$fitlers-data-for-container-tablet-width: 260px;

//api-modals

$api-form-header-margin: 10px;
$chevron-margin: 0 0.3em;
$picker-size: 32px;
$picker-top: 0;
$picker-zindex: 1050;
$picker-font-size: 80%;
$api-form-padding: 0 69px;
$date-margin: 10px 0;
$picker-padding: 10px;
$api-button-margin: 30px;
$api-stepper-zindex: 1051;
$stepper-back-color:#B9B9B9;
$date-icon-right: 10px;
$date-icon-top: 9px;
$date-input-width: 153px;
$api-go-btn-color: #66CB79;
$api-go-btn-font-col: #1C1C1C;
$api-go-btn-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.25));
$api-dropdown-height: 200px;
$filters-select-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
$filters-select-box-shadow-hover: 0px 4px 10px rgba(0, 0, 0, 0.07);
$h3-margin-top: 50px;
$custom-select-item-height: 36px;
$custom-select-padding: 0 15px;

// profile

$profile-content-padding: 40px 80px 40px 40px;
$profile-content-width: 640px;
$profile-content-height: calc(100vh - 90px);
$profile-header-padding: 0 40px;
$profile-header-font-color: #6A6B6F;
$profile-password-p-color: #B9B9B9;
$profile-header-height: 90px;
$profile-options-width: 280px;
$profile-options-border: 1px solid #D9D9D9;
$profile-option-border: 1px solid #F5F5F5;
$profile-options-height: calc(100vh - 100px);
$profile-option-font-color: #606061;
$profile-option-padding: 0 20px;
$profile-form-margins: 20px;
$profile-inputs-radius: 6px;
$profile-default-button-color: #898989;
$profile-default-button-color-hov: #7b7979;
$profile-h2-color: #333333;
$profile-h2-margin: 12px;
$profile-submit-btn-background: linear-gradient(90.04deg, #5CA76A 0.9%, #53C835 99.97%);
$profile-submit-btn-background-hov: linear-gradient(90.04deg, #5CA76A 0.9%, #5CA76A 30%);
$profile-required-label-color: #DE4242;
$profile-required-label-right: -10px;
$profile-avatar-size: 77px;
$profile-group-input-width: 48%;
$profile-avatar-icon-top: 8px;
$profile-avatar-icon-right: -3px;
$profile-header-bell-size: 20px;
$profile-header-avatar-size: 41px;
$profile-header-avatar-margin: 0 10px;
$profile-input-edit-left: 101%;
$profile-input-edit-top: 28px;
$profile-input-edit-height: 38px;
$profile-input-edit-padding: 0 10px;
$phone-flag-padding: 6px 0 0 6px;
$phone-border: 1px solid rgba(34,36,38,.15);
$input-clear-button-top: 40px;
$edit-avatar-icon-size: 19px;
$forgot-password-btn-bottom: -50px;
$forgot-password-btn-right: 20px;

//service-provider

$service-provider-wrapper-padding: 40px 60px 0 60px;
$service-provider-table-padding: 40px;
$service-provider-tr-border: 1px solid #BDBDBD;
$service-provider-tr-height: 50px;
$service-provider-table-cell-padding: 18px;
$status-color-size: 16px;
$booking-header-margin: 20px;
$booking-header-color: #1A9641;
$booking-h3: 16px;
$booking-filter-btns-height: 35px;
$booking-filter-border-color:#D9D9D9;
$booking-filter-border-active-color:#2F80ED;
$booking-filter-btns-icons-h:16px;
$booking-filter-btns-icons-margin:6px;
$booking-filter-btns-padding:0 12px;
$booking-filter-btns-last-radius:0 5px 5px 0;
$booking-filter-btns-first-radius:5px 0 0 5px;

$search-input-height: 40px;
$search-input-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
$search-input-radius: 8px;
$search-input-width: 270px;

$request-status-item-height: 30px;
$request-status-item-padding: 0 10px;
$request-status-item-font: 13px;
$request-status-item-decline-col: #E73D3D;
$request-status-item-approve-col: #008246;
$request-status-item-details-col: #A5A2A2;
$request-status-item-hover-op: 0.8;

$seeder-selectiors-height: 40px;
$seeder-date-select-width: 200px;
$seeder-num-select-width: 300px;
$seeder-date-img-margin: 10px;
$seeder-time-select-text-margin: 30px;
$seeder-time-select-img-left: 10px;
$seeder-time-select-img-top: 9px;
$seeder-time-select-img-width: 20px;
$seeder-time-select-img-zindex: 100px;
$seeder-time-select-top: 9px;
$seeder-time-select-left: 14px;

//dam-water-levels

$dam-map-height: calc(100vh - 320px);
$left-map-width: calc( 100vw - 720px - 350px);
$left-map-right: 350px;
$right-map-width: 350px;

$drag-left: -4px;
$drag-width: 8px;
$drag-background: #dee2e6db;
$drag-radius: 5px;
$drag-zindex: 1000;

$dams-graph-container-height: 220px;
$dams-graph-container-margin: 10px;

$dams-filters-selector-width: 300px;

$dams-insights-margin: 10px 0 0 0;

// map-controls

$control-btn-size: 32px;
$control-btn-color: #222222;
$control-btn-hover: #484646;
$control-icon-color: #E7E7E7;
