@import "./assets/variables/index.scss";

@font-face {
  font-family: "MontserratMedium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratRegular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("opentype");
  font-display: swap;
}

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: $main-background-color;
}

body, button, .ui.button {
  font-family: $main-font;
  font-style: $main-font-style;
  font-weight: $main-font-weight;
}

input, select {
  font-family: $secondary-font;
  font-style: $main-font-style;
  font-weight: $secondary-font-weight;
}

a {
  color: $auth-link-color;
}

// .ui.modal > .content {
//   height: 100%;
// }

.ui.modal {
  height: 500px;
}

.ui.page.dimmer {
  z-index: 10000
}

.ui.dimmer .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}

.ui.dimmer .ui.loader:after {
  border-color: #767676 transparent transparent;
}

.list-group-flush {
  color: #495057;
}

.navbar-brand {
  margin-left: 15px;
  color: #2196f3 !important;
}

.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 270px;
}

#content {
  margin-left: 270px;
}

.list-group-item {
  display: block !important;
  transition: background-color 0.3s;
}

.list-group-item:hover {
  color: #49505b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item:hover {
  color: #49505b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.card-subtitle {
  font-size: 1.25rem;
  font-weight: 600;
}

.full {
  height: 70vh;
}

.bad-gateway-row {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card h3,
.card.card-cascade h4 {
  margin-bottom: 0px;
}

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

.cascading-admin-card .admin-up .fa {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.form-inline {
  flex-flow: unset;
}

.breadcrumb {
  margin: 0;
}

.activeClass .list-group-item {
  z-index: 5;
  color: #fff;
  border-color: #007bff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px !important;
  background-color: #007bff !important;
}

.page-footer {
  margin-left: 270px;
}

#btn-logout {
  cursor: pointer;
}

.main-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 213px;
}

.main-container .page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.table {
  text-align: center;
  margin: 0 !important;
}

.sc-bdVaJa {
  cursor: pointer;
}

.date-picker {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.data-picker-is-invalid {
  border-color: #dc3545;
}

.pointer {
  cursor: pointer;
}

.invalid-feedback {
  position:absolute;
  top: $auth-validatiors-top;
  width: max-content;
}

.ui.modal {
  position: static;
}
