@import "../../../assets/variables/index.scss";

.auth-wrapp {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .auth-footer-wrapp {
      position: fixed;
      display: flex;
      bottom: $auth-footer-margin;
      .footer-item-wrapp {
        padding: $auth-footer-item-padding;
        color: $auth-footer-color;
        font-size: $auth-agreement-font-size;
        font-family: $secondary-font; 
        a {
          p {
            font-size: $auth-agreement-font-size;
            color: $auth-footer-color;
            font-family: $secondary-font; 
          }
          &:hover {
            p {
              color: $auth-footer-hover-color;
            }
          }
        }
      }
    }
    .auth-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: $auth-container-width;
      box-shadow: $auth-container-boxshadow;
      padding: $auth-container-padding;
      border-radius: $auth-border-radius;
      background-color: $auth-default-background-color;
      z-index: $auth-container-zindex;
      .error-field {
        position: absolute;
        // top: $auth-validatiors-top;
        font-size: $auth-validatiors-font-size;
        color: $auth-validators-font-color;
        &.hook-error {
          top: $auth-validatiors-errors-top;
          width: max-content;
        }
      }
      .auth-agreement {
        position: relative;
        margin-top: $auth-agreement-margin;
        p {
          font-size: $auth-agreement-font-size;
          font-family: $secondary-font;
          font-weight: $secondary-font-weight;
          line-height: $auth-agreement-line-height;
        }
      }
      .form-group {
        font-size: $main-font-size;
        .form-control {
          height: $auth-input-height;
          border-radius: $auth-border-radius;
          background: $auth-input-background-color;
          margin-bottom: $auth-input-margin;
          font-size: $main-font-size;
          &:hover {
            border-color: $auth-input-hover-color;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
      .auth-button-wrapp {
        margin-top: $auth-button-margin;
        &.sign-in-btn {
          margin-top: $signin-button-margin;
        }
        .ui.button.auth-button {
          background: $main-green-color;
          height: $auth-input-height;
          border-radius: $auth-border-radius;
          color: $auth-default-background-color;
          font-size: $auth-button-font-size;
          position: relative;
          &:hover {
            opacity: 0.85;
          }
        }
      }
      .auth-toggle {
        width: $auth-input-width;
        background: $auth-toggle-background-color;
        border-radius: $auth-border-radius;
        height: $auth-input-height;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $auth-input-margin;
        position: relative;
        // p {
        //   &:hover {
        //     color: $auth-button-hover;
        //   }
        // }
        .active-item {
          border-radius: $auth-border-radius;
          background: $auth-default-background-color;
          width: $auth-toggle-item-width;
          height: $auth-toggle-item-height;
          position: absolute;
          transition: $main-transition;
          &.signIn-toggle {
            left: $auth-toggle-login-position;
          }
          &.signUp-toggle {
            left: $auth-toggle-signup-position;
          }
        }
        .toggle-item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          z-index: 1;
        }
      }
      p {
        font-size: $auth-button-font-size;

      }
      .auth-logo {
        margin-bottom: $auth-input-margin;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }
