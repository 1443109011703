@import "../../../assets/variables/index.scss";

.auth-form {
  font-size: $main-font-size;
  width: 100%;
  .auth-input {
    width: 100%;
    label {
      font-size: $auth-label-font-size;
    }
    .ui.icon.input>i.icon:not(.link) {
      height: $auth-input-height;
      pointer-events: unset;
      cursor: pointer;
    }
    .ui.icon.input>i.icon:after, .ui.icon.input>i.icon:before {
      transform: scale(0.7);
    }
    .ui.label {
      background: none;
    }
    .ui.input {
      width: 100%;
      &.error-input-border {
        input {
          border-color: $auth-validators-font-color;
        }
      }
    }
    input {
      height: $auth-input-height;
      border-radius: $auth-border-radius;
      background: $auth-input-background-color;
      margin-bottom: $auth-input-margin;
      font-size: $auth-label-font-size !important;
      font-family: $secondary-font;
      width: 100%;
      &:hover {
        border-color: $auth-input-hover-color;
      }
      &:focus {
        box-shadow: none;
        background: $auth-input-background-color;
      }
    }
  }
}